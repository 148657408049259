import DASHBOARD_EN from "src/components/Dashboard/i18n/en";
import SIGN_IN_EN from "src/components/Sign-in/i18n/en";
import ADMINISTRATION_EN from "src/components/Administration/i18n/en";
import ALERTS_EN from "src/components/Alerts/i18n/en";
import CONFIGURATION_EN from "src/components/Configuration/i18n/en";
import ODOR_REPORTS_EN from "src/components/OdorReports/i18n/en";
import OPEN_DATA_AIR_QUALITY_EN from "src/components/OpenDataAir/i18n/en";
import OPEN_DATA_METEOROLOGY_EN from "src/components/OpenDataMeteo/i18n/en";
import SINGLE_MULTIMEDIA_EN from "src/components/SingleMultimedia/i18n/en";
import SINGLE_STATION_EN from "src/components/SingleStationHandling/i18n/en";
import STATIONS_EN from "src/components/StationHandling/i18n/en";
import USER_PROFILE_EN from "src/components/UserProfile/i18n/en";
import MULTIMEDIA_EN from "src/components/Multimedia/i18n/en";
import VESSELS_EN from "src/components/VesselsTraffic/i18n/en";
import TEST_PAGE_EN from "src/components/TestPage/i18n/en";
import FOOTER_EN from "src/components/SharingComponents/i18n/Footer/en";
import { MONGO_CONSTANTS_EN } from "./mongoCostants/constants_en";
import { navbarGroupsNamesEn } from "./navBarGroups/groups_en";
import SHARING_COMPONENTS_EN from "src/components/SharingComponents/i18n/en";
import MODAL_COMPONENTS_EN from "src/components/Modals/i18n/en";
import INPUT_COMPONENTS_EN from "src/components/SharingComponents/Inputs/i18n/en";
import INTERNAL_NETWORK_MANAGEMENT_EN from "src/components/InternalNetworkMan/i18n/en";
// import INTERACTIVE_MAP_EN from "src/components/InteractiveMap/i18n/en";
import TRASH_EN from "src/components/Trash/i18n/en";
import { SELF_CONTAINED_VIEWS_EN } from "./self_contained_views/views_en";
import { MODALS_EN } from "./modals/modals_en";

const colors = {
	YELLOW: "Yellow",
	GREEN: "Green",
	BLUE: "Blue",
	RED: "Red",
	GRAY: "Grey",
}

const generalTerms = {
	STATION : "Station",
	STATIONS : "Stations",
	OWNER : "Owner",
	UNKNOWN: "Unknown",
	MONITORING_STATIONS : "Monitoring stations",
	STATISTICS: "Stats",
	THRESHOLD: "Threshold",
	MAX_THRESHOLD: "Max threshold",
	MIN_THRESHOLD: "Min threshold",
	THRESHOLDS: "Thresholds",
	THRESHOLD_EXCEEDED: "Threshold values exceeded",
	DEVICE: "Device",
	DEVICE_TYPE: "Device type",
	VALUE: "Value",
    AVERAGE: "Average",
    CATEGORY: "Category",
    LAST_RELEVATION: "Latest detection",
	AVG: "Average",
	SD: "Standard deviation",
	MEDIAN: "Median",
	VAR: "Variance",
	SELECT_STATION: "Select a station",
	NO_SENSORS_INSTALLED: 'There are no sensors installed',
	CONTROL_UNIT: "Control unit",
	NO_DATA: "No data",
	CONTROL_UNITS_INSTALLED: "Control units installed",
	SENSOR: "Sensor",
	SENSORS: "Sensors",
	SENSORS_INSTALLED: "Sensors installed",
	SENSORS_TYPE: "Sensor type",
	STATION_TYPE: "Station type",
	TYPE: "Type",
	LEVEL: "Level",
	MEASURE : "Measure",
	SOURCE : "Source",
	ALL: "All",
	INTERVAL: "Interval",
	SERIAL : "Serial",
	NAME: "Name",
	USER: "User",
	TRAFFIC: "Traffic",
	AVAILABLE_STATIONS: "Available stations",
	UNAVAILABLE_STATIONS: "Unavailable stations",
	CONTROL_UNITS: "Control units",
	MEASURES: "Measures",
	ALL_PARAMS: "All params",
	ALL_DEVICES: "All devices",
	ADD: "Add",
	DELETE: "Delete",
	RESTORE: "Restore",
	ODOUR_LEVEL: "Level",
	DATE: 'Date',
	ON_THE_DATE_OF: "On",
	DEPARTURE: "Departure",
	ARRIVAL: "Arrival",
	BASE_LOCATION: "Default position",
	LAST_POSITION: "Last position",
	CURRENT: 'Current',
	POSITION: "Position",
	NEW : "New",
	EXISTING: "Existing",
	SAVE: "Save",
	CONFIRM: "Confirm",
	CANCEL: "Cancel",
	CREATE_ACCOUNT : "Create account",
	LAST_RELEVATIONS: "Last detections",
	SAVING: "Saving",
	DISCARD_CHANGES: "Discard changes",
	CHANGE: "Change",
	INSTALL: "Install",
	INSTALL_ON: "Install on",
	INSTALLED_ON: "Installed on",
	ACTIVE : "Active",
	ACTIVABLE : "Activable",
	DEACTIVATE: "Deactivate",
	SENSOR_TYPE: "Sensor type",
	NO_THRESHOLDS: "No thresholds",
	HISTORY: "History",
	GENERAL: "General",
	HEADQUARTER: "Headquarter",
	START_DATE: "Start date",
	END_DATE: "End date",
	SUCCESS: "Success",
	ERROR: "Error",
	SURE: "Are you sure",
	LANGUAGE: "Language",
	SESSION_EXPIRED: "Session expired",
	GO_TO_LOGIN : "Go to login",
	WELCOME: "Welcome",
	TENANT: "Tenant",
	TENANTS: "Tenants",
	USERS: "Users",
	ROLE: "Role",
	ROLES: "Roles",
	FILTERS: "Filters",
	GLOBAL_VARIABLES: "Global variables",
	SHARING: "Sharing",
	DESCRIPTION: "Description",
	EDIT: "Edit",
	IDENTIFIER: "Identifier",
	MEASUREMENT_UNIT: "Measurement unit",
	COLOR: "Color",
	TITLE: "Title",
	MESSAGE: "Message",
	TIME: "Time",
	ACTION: "Action",
	ACTIONS: "Actions",
	NOTIFICATION: "Notification",
	NOTIFICATIONS: "Notifications",
	NOTIFICATION_TYPE: "Notification type",
	EXTENSION : "Extension",
    RESOLUTION : "Resolution",
    SIZE : "Size",
	IMAGES: "Images",
	STATE: "State",
	EXPORT: "Export",
	SECONDS: "Seconds",
	NO_STATIONS_AVAILABLE: "No stations available",
	DATA: "Data",
	MAINTENANCE: "Maintenance",
	OTHERS: "Others",
	DESTINATION: "Destination",
	POLAR_LEGEND_1: "This is a polar plot derived from the integration of VOC and meteorological data from the last 24 hours. From",
    POLAR_LEGEND_2: "(lowest probability) to",
    POLAR_LEGEND_3: "(highest probability) represents an increasing probability of finding the source of pollution.", 
	FLAG: "Flag",
	SEARCH: "Search",
	LATITUDE: "Latitude",
	LONGITUDE: "Longitude",
	ACTION_GROUP: "Action group",
	FILTERS_IN_VIEW: "Filters in view",
	REQUIRED_INPUT: "This input is required",
	PARAM: "Parameter",
	PARAMS: "Parameters",
	ALREADY_EXIST: "already exist", 
	UPDATE_INTERVAL: "Update interval",
	UNIT_OF_MEASUREMENT: "Unit of measurement",
	ALERT_TYPE: "Alert type",
	ALERT_CATEGORY: "categoryAlert",
	SHARED_WITH: "Shared with",
	REFRESH: "Refresh",
	SHOW_POSITION: "Show position",
	SHOW_PATH: "Show path",
	ENABLED_VIEWS: "Enabled views",
	PERMITTED_ACTIONS: "Permitted actions",
	GO_TO: "Go to",
	GO_TO_STATIONS: "Go to stations",
	GO_TO_MULTIMEDIA: "Go to multimedia",
	VIEWS: "Views",
	CAN_SHARE_WITH: "Can share with",
	TENANT_ACTIONS: "Tenant actions",
	USER_LIMIT: "User limit",
	INVALID_DATE_ERROR: "Invalid date",
	MIN_DATE_ERROR: "The end date cannot be earlier than the start date",
	MAX_DATE_ERROR_GENERIC: "The selected range cannot exceed the maximum allowed range",
	MAX_DATE_ERROR: "The selected range cannot exceed",
	DISABLE_FUTURE_ERROR: "Future date",
	WORKING: "Working",
	PARTIALLY_WORKING: "Partially working",
	NOT_WORKING: "Not working",
	NOT_AVAILABLE: "Not available",
	CHECK_INVITATION : "Check invitations",
	AVAILABLE: "Available",
	ACTIVE_STATIONS: "Active stations",
	CUSTOMIZE_TIME_RANGE: "Customize dates",
	LAST_UPDATE: "Last update",
	CONNECT: "Connect",
	DISCONNECT: "Disconnect",
	NO_ACTIVE_STATIONS: "No active stations",
	NO_ACTIVE_DEVICES: "No active devices",
	NEW_PLURAL: "New",
	CORRELATION_MATRIX: "Correlation matrix",
	MIN_AND_MAX : "Minimum and maximum",
	MAX: "Maximum",
	MIN: "Minimum",
	STATS_NOT_AVAILABLE: "Statistics not available",
	CLOSE: "Close",
	SELECT_LAYER: "Select layer",
	ACTIVE_LAYER: "Active layer",
	MOTIVATION: "Motivation",
	POSITION_ROUTE_NOT_AVAILABLE: "Position or route not available",
	ALL_STATIONS: "All stations",
	ALL_DATA_SOURCES: "All data sources",
	MINUTE: "Minute",
	MINUTES: "Minutes",
	HOUR: "Hour",
	HOURS: "Hours",
	THEME: "Theme",
	MARK_AS_READ: "Mark all as read",
	DAY: "Day",
	DAYS: "Days",
	MONTH: "Month",
	MONTHS: "Months",
	TYPOLOGY: "Typology",
	AIR_QUALITY: "Air quality",
	WATER_QUALITY: "Water quality",
	EEA: "European Environment Agency",
	PARAMETERS_DETECTED: "Parameters detected",
	AUTHENTICATION_ERROR_TITLE: "Authentication error",
	AUTHENTICATION_ERROR_MSG_0: "Authentication service is not responding. Check your internet connection or contact your administrator",
	AUTHENTICATION_ERROR_MSG_1: "Incorrect username/password please try again",
	AUTHENTICATION_ERROR_MSG_2: "Service temporarily unavailable",
	NEW_ALERT: "New alert",
	REPORT: "Report",
	REPORTS: "Reports",
	VESSEL: "Vessel",
	VESSELS: "Vessels",
	WIND_CALM: "Wind calm",
	STATS: "Stats",
};

const vesselTypes = {
	VESSEL_TYPE_1: "Reserved for future use",
	VESSEL_TYPE_2: "Reserved for future use",
	VESSEL_TYPE_3: "Reserved for future use",
	VESSEL_TYPE_4: "Reserved for future use",
	VESSEL_TYPE_5: "Reserved for future use",
	VESSEL_TYPE_6: "Reserved for future use",
	VESSEL_TYPE_7: "Reserved for future use",
	VESSEL_TYPE_8: "Reserved for future use",
	VESSEL_TYPE_9: "Reserved for future use",
	VESSEL_TYPE_10: "Reserved for future use",
	VESSEL_TYPE_11: "Reserved for future use",
	VESSEL_TYPE_12: "Reserved for future use",
	VESSEL_TYPE_13: "Reserved for future use",
	VESSEL_TYPE_14: "Reserved for future use",
	VESSEL_TYPE_15: "Reserved for future use",
	VESSEL_TYPE_16: "Reserved for future use",
	VESSEL_TYPE_17: "Reserved for future use",
	VESSEL_TYPE_18: "Reserved for future use",
	VESSEL_TYPE_19: "Reserved for future use",
	VESSEL_TYPE_20: "Reserved for future use",
	VESSEL_TYPE_21: "Wing In Ground (Hazardous category A)",
	VESSEL_TYPE_22: "Wing In Ground (Hazardous category B)",
	VESSEL_TYPE_23: "Wing In Ground (Hazardous category C)",
    VESSEL_TYPE_24: "Wing In Ground (Hazardous category D)",
	VESSEL_TYPE_25: 'Wing in ground (WIG), Reserved for future use',
	VESSEL_TYPE_26: 'Wing in ground (WIG), Reserved for future use',
	VESSEL_TYPE_27: 'Wing in ground (WIG), Reserved for future use',
	VESSEL_TYPE_28: 'Wing in ground (WIG), Reserved for future use',
	VESSEL_TYPE_29: 'Wing in ground (WIG), Reserved for future use',
	VESSEL_TYPE_30: 'Fishing',
	VESSEL_TYPE_31: 'Towing',
	VESSEL_TYPE_32: 'Towing: length exceeds 200m or breadth exceeds 25m',
	VESSEL_TYPE_33: 'Dredging or underwater ops',
	VESSEL_TYPE_34: 'Diving ops',
	VESSEL_TYPE_35: 'Military ops',
	VESSEL_TYPE_36: "Sailing",
	VESSEL_TYPE_37: 'Pleasure Craft',
	VESSEL_TYPE_38: 'Reserved',
	VESSEL_TYPE_39: 'Reserved',
	VESSEL_TYPE_40: 'High speed craft (HSC), all ships of this type',
	VESSEL_TYPE_41: 'High speed craft (HSC), Hazardous category A',
	VESSEL_TYPE_42: 'High speed craft (HSC), Hazardous category B',
	VESSEL_TYPE_43: 'High speed craft (HSC), Hazardous category C',
	VESSEL_TYPE_44: 'High speed craft (HSC), Hazardous category D',
	VESSEL_TYPE_45: 'High speed craft (HSC), Reserved for future use',
	VESSEL_TYPE_46: 'High speed craft (HSC), Reserved for future use',
	VESSEL_TYPE_47: 'High speed craft (HSC), Reserved for future use',
	VESSEL_TYPE_48: 'High speed craft (HSC), Reserved for future use',
	VESSEL_TYPE_49: 'High speed craft (HSC), No additional information',
	VESSEL_TYPE_50: "Pilot Vessel",
	VESSEL_TYPE_51: "Search and Rescue vessel",
	VESSEL_TYPE_52: "Tug",
	VESSEL_TYPE_53: 'Port Tender',
	VESSEL_TYPE_54: 'Anti-pollution equipment',
	VESSEL_TYPE_55: "Law Enforcement",
	VESSEL_TYPE_56: 'Spare - Local Vessel',
	VESSEL_TYPE_57: 'Spare - Local Vessel',
	VESSEL_TYPE_58: 'Medical Transport',
	VESSEL_TYPE_59: 'Noncombatant ship according to RR Resolution No. 18',
	VESSEL_TYPE_60: "Passenger",
	VESSEL_TYPE_61: 'Passenger, Hazardous category A',
	VESSEL_TYPE_62: 'Passenger, Hazardous category B',
	VESSEL_TYPE_63: 'Passenger, Hazardous category C',
	VESSEL_TYPE_64: 'Passenger, Hazardous category D',
	VESSEL_TYPE_65: 'Passenger, Reserved for future use',
	VESSEL_TYPE_66: 'Passenger, Reserved for future use',
	VESSEL_TYPE_67: 'Passenger, Reserved for future use',
	VESSEL_TYPE_68: 'Passenger, Reserved for future use',
	VESSEL_TYPE_69: 'Passenger, No additional information',
	VESSEL_TYPE_70: "Cargo",
	VESSEL_TYPE_71: 'Cargo, Hazardous category A',
	VESSEL_TYPE_72: 'Cargo, Hazardous category B',
	VESSEL_TYPE_73: 'Cargo, Hazardous category C',
	VESSEL_TYPE_74: 'Cargo, Hazardous category D',
	VESSEL_TYPE_75: 'Cargo, Reserved for future use',
	VESSEL_TYPE_76: 'Cargo, Reserved for future use',
	VESSEL_TYPE_77: 'Cargo, Reserved for future use',
	VESSEL_TYPE_78: 'Cargo, Reserved for future use',
	VESSEL_TYPE_79: 'Cargo, No additional information',
	VESSEL_TYPE_80: 'Tanker, all ships of this type',
	VESSEL_TYPE_81: 'Tanker, Hazardous category A',
	VESSEL_TYPE_82: 'Tanker, Hazardous category B',
	VESSEL_TYPE_83: 'Tanker, Hazardous category C',
	VESSEL_TYPE_84: 'Tanker, Hazardous category D',
	VESSEL_TYPE_85: 'Tanker, Reserved for future use',
	VESSEL_TYPE_86: 'Tanker, Reserved for future use',
	VESSEL_TYPE_87: 'Tanker, Reserved for future use',
	VESSEL_TYPE_88: 'Tanker, Reserved for future use',
	VESSEL_TYPE_89: 'Tanker, No additional information',
	VESSEL_TYPE_90: "Other Types",
    VESSEL_TYPE_91: "Other Types (Hazardous category A)",
    VESSEL_TYPE_92: "Other Types (Hazardous category B)",
    VESSEL_TYPE_93: "Other Types (Hazardous category C)",
    VESSEL_TYPE_94: "Other Types (Hazardous category D)",
	VESSEL_TYPE_99: "Other Types (no information)",
	VESSEL_TYPE_999: "Ecotaras",
};

const months = {
	MONTH_0: "January",
	MONTH_1: "February",
	MONTH_2: "March",
	MONTH_3: "April",
	MONTH_4: "May",
	MONTH_5: "June",
	MONTH_6: "July",
	MONTH_7: "August",
	MONTH_8: "September",
	MONTH_9: "October",
	MONTH_10: "November",
	MONTH_11: "December",
  };
  
  const formValidationErrorMessages = {
	ERR_FORM_EMAIL_INVALID : "Invalid email address",
	ERR_FORM_EMAIL_REQUIRED : "Email is required",
	ERR_FORM_USERNAME_REQUIRED : "Username is required",
	ERR_FORM_PASSWORD_SHORT : "Password must be at least 8 characters long",
	ERR_FORM_PASSWORD_UPPERCASE : "Password must contain at least one uppercase letter.",
  	ERR_FORM_PASSWORD_LOWERCASE : "Password must contain at least one lowercase letter.",
  	ERR_FORM_PASSWORD_NUMBER : "Password must contain at least one number.",
	ERR_FORM_PASSWORD_REQUIRED : "Password is required",
	ERR_FORM_LANGUAGE_REQUIRED : "Language is required",
};

const locale = {
	...SIGN_IN_EN,
	...DASHBOARD_EN,
	...ADMINISTRATION_EN,
	...ALERTS_EN,
	...CONFIGURATION_EN,
	...ODOR_REPORTS_EN,
	...OPEN_DATA_AIR_QUALITY_EN,
	...OPEN_DATA_METEOROLOGY_EN,
	...SINGLE_MULTIMEDIA_EN,
	...SINGLE_STATION_EN,
	...STATIONS_EN,
	...USER_PROFILE_EN,
	...MULTIMEDIA_EN,
	...VESSELS_EN,
	...TEST_PAGE_EN,
	...FOOTER_EN,
	...generalTerms,
	...colors,
	...navbarGroupsNamesEn, 
	...MONGO_CONSTANTS_EN,
	...SHARING_COMPONENTS_EN,
	...MODAL_COMPONENTS_EN,
	...INPUT_COMPONENTS_EN,
	...INTERNAL_NETWORK_MANAGEMENT_EN,
	// ...INTERACTIVE_MAP_EN,
	...TRASH_EN,
	...SELF_CONTAINED_VIEWS_EN,
	...MODALS_EN,
	...vesselTypes, 
	...months,
	...formValidationErrorMessages,
};


export default locale;