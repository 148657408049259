import { viewNames } from "app/configs/routesNames";
import { createLanguageObject } from "src/utilities/utils";

let USER_INVITATION_IT = {
  REGISTRATION_SUCCESS: "Registrazione completata con successo",
  CONFIRM_REGISTRATION: "Conferma la tua registrazione",
  REGISTER: "Registrati",
  ENTER_DETAILS: "Inserisci correttamente i tuoi dati",
  CALL_TO_ACTION:
    "Proteggi il tuo futuro, inizia oggi! Con Evocs puoi sfruttare il \
    	potere dell'intelligenza artificiale per un mondo più sostenibile. \
    	Evocs è la piattaforma di Tilebytes s.r.l. che ti permette di \
    	monitorare in tempo reale la qualità dell'aria nella tua città, \
    	ricevere avvisi in caso di picchi di inquinamento, contribuire a \
    	creare una rete di dati per la tutela dell'ambiente e molto altro. \
    	Registrati ora su Evocs e: Scopri i dati sulla qualità dell'aria \
    	nella tua zona. Confronta i dati con altre città e paesi. \
    	Personalizza i tuoi avvisi e ricevili direttamente sul tuo \
    	smartphone. Insieme, possiamo fare la differenza!.",
  SUBTITLE: "Benvenuto/a nella nostra community",
  SIGN_UP_NOW: "Iscriviti ora",
  ACCOUNT: "Hai già un account?",
  SIGN_IN: "Accedi",
  DESCRIPTION:
    "Evocs è la piattaforma di Tilebytes s.r.l. che ti permette di monitorare in tempo reale la qualità dell'aria nella tua città, ricevere avvisi in caso di picchi di inquinamento, contribuire a creare una rete di dati per la tutela dell'ambiente e molto altro.",
  EMAIL_VERIFIED_SUCCESSFULLY: "Email verificata con successo!",
  CREATE_PROFILE_IN_SECONDS: "Ora crea il tuo profilo in pochi secondi",
};

USER_INVITATION_IT = createLanguageObject(
  USER_INVITATION_IT,
  viewNames.USER_INVITATION_VIEW
);

export const SELF_CONTAINED_VIEWS_IT = {
  ...USER_INVITATION_IT,
};
