import DASHBOARD_IT from "src/components/Dashboard/i18n/it";
import SIGN_IN_IT from "src/components/Sign-in/i18n/it";
import ADMINISTRATION_IT from "src/components/Administration/i18n/it";
import ALERTS_IT from "src/components/Alerts/i18n/it";
import CONFIGURATION_IT from "src/components/Configuration/i18n/it";
import ODOR_REPORTS_IT from "src/components/OdorReports/i18n/it";
import OPEN_DATA_AIR_QUALITY_IT from "src/components/OpenDataAir/i18n/it";
import OPEN_DATA_METEOROLOGY_IT from "src/components/OpenDataMeteo/i18n/it";
import SINGLE_MULTIMEDIA_IT from "src/components/SingleMultimedia/i18n/it";
import SINGLE_STATION_IT from "src/components/SingleStationHandling/i18n/it";
import STATIONS_IT from "src/components/StationHandling/i18n/it";
import USER_PROFILE_IT from "src/components/UserProfile/i18n/it";
import MULTIMEDIA_IT from "src/components/Multimedia/i18n/it";
import VESSELS_IT from "src/components/VesselsTraffic/i18n/it";
import TEST_PAGE_IT from "src/components/TestPage/i18n/it";
import FOOTER_IT from "src/components/SharingComponents/i18n/Footer/it";
import { MONGO_CONSTANTS_IT } from "./mongoCostants/costants_it";
import { navbarGroupsNamesIt } from "./navBarGroups/groups_it";
import SHARING_COMPONENTS_IT from "src/components/SharingComponents/i18n/it";
import MODAL_COMPONENTS_IT from "src/components/Modals/i18n/it";
import INPUT_COMPONENTS_IT from "src/components/SharingComponents/Inputs/i18n/it";
import INTERNAL_NETWORK_MANAGEMENT_IT from "src/components/InternalNetworkMan/i18n/it";
// import INTERACTIVE_MAP_IT from "src/components/InteractiveMap/i18n/it";
import TRASH_IT from "src/components/Trash/i18n/it";
import { SELF_CONTAINED_VIEWS_IT } from "./self_contained_views/views_it";
import { MODALS_IT } from "./modals/modals_it";

const colors = {
	YELLOW: "Giallo",
	GREEN: "Verde",
	BLUE: "Blu",
	RED: "Rosso",
	GRAY: "Grigio",
}

const generalTerms = {
	STATION : "Stazione",
	STATIONS : "Stazioni",
	OWNER : "Proprietario",
	UNKNOWN: "Sconosciuto",
	MONITORING_STATIONS : "Stazioni di monitoraggio",
	STATISTICS: "Statistiche",
	THRESHOLD: "Soglia",
	THRESHOLDS: "Soglie",
	MAX_THRESHOLD: "Soglia massima",
	MIN_THRESHOLD: "Soglia minima",
	THRESHOLD_EXCEEDED: "Superamenti di soglia",
	DEVICE: "Device",
	DEVICE_TYPE: "Tipo device",
	VALUE: "Valore",
    AVERAGE: "Media",
    CATEGORY: "Categoria",
    LAST_RELEVATION: "Ultima rilevazione",
	AVG: "Media",
	SD: "Deviazione standard",
	MEDIAN: "Mediana",
	VAR: "Varianza",
	SELECT_STATION: "Seleziona una stazione",
	NO_SENSORS_INSTALLED: 'Non ci sono sensori installati',
	CONTROL_UNIT: "Centralina",
	NO_DATA: "Nessun dato",
	CONTROL_UNITS_INSTALLED: "Centraline installate",
	SENSOR: "Sensore",
	SENSORS: "Sensori",
	SENSORS_INSTALLED: "Sensori installati",
	SENSORS_TYPE: "Tipo sensore",
	STATION_TYPE: "Tipo stazione",
	TYPE: "Tipo",
	LEVEL: "Livello",
	MEASURE : "Misura",
	SOURCE : "Sorgente",
	ALL: "Tutti/e",
	INTERVAL: "Intervallo",
	SERIAL : "Seriale",
	NAME: "Nome",
	USER: "Utente",
	TRAFFIC: "Traffico",
	AVAILABLE_STATIONS: "Stazioni attive",
	UNAVAILABLE_STATIONS: "Stazioni disattive",
	CONTROL_UNITS: "Centraline",
	MEASURES: "Misure",
	ALL_PARAMS: "Tutti i parametri",
	ALL_DEVICES: "Tutti i dispositivi",
	ADD: "Aggiungi",
	DELETE: "Elimina",
	RESTORE: "Ripristina",
	ODOUR_LEVEL: "Livello",
	DATE: 'Data',
	ON_THE_DATE_OF: "In data",
	DEPARTURE: "Partenza",
	ARRIVAL: "Arrivo",
	BASE_LOCATION: "Posizione di default",
	LAST_POSITION: "Ultima posizione",
	CURRENT: 'Attuale',
	POSITION: "Posizione",
	NEW : "Nuovo/a",
	EXISTING: "Esistente",
	SAVE: "Salva",
	CONFIRM: "Conferma",
	CANCEL: "Annulla",
	CREATE_ACCOUNT : "Crea account",
	LAST_RELEVATIONS: "Ultime rilevazioni",
	SAVING: "Salvataggio",
	DISCARD_CHANGES: "Annulla modifiche",
	CHANGE: "Cambia",
	INSTALL: "Installa",
	INSTALL_ON: "Installa su",
	INSTALLED_ON: "Installato/a su",
	ACTIVE : "Attivo/a",
	ACTIVABLE : "Attivabile",
	DEACTIVATE: "Disattiva",
	SENSOR_TYPE: "Tipologia del sensore",
	NO_THRESHOLDS: "Nessuna soglia",
	HISTORY: "Storico",
	GENERAL: "Generali/e",
	HEADQUARTER: "Sede",
	START_DATE: "Data inizio",
	END_DATE: "Data fine",
	SUCCESS: "Successo",
	ERROR: "Errore",
	SURE: "Sei sicuro",
	LANGUAGE: "Lingua",
	SESSION_EXPIRED: "Sessione scaduta",
	GO_TO_LOGIN : "Torna al login",
	WELCOME: "Benvenuto",
	TENANT: "Tenant",
	TENANTS: "Tenant",
	USERS: "Utenti",
	ROLE: "Ruolo",
	ROLES: "Ruoli",
	FILTERS: "Filtri",
	GLOBAL_VARIABLES: "Variabili globali",
	SHARING: "Condivisione",
	DESCRIPTION: "Descrizione",
	EDIT: "Modifica",
	IDENTIFIER: "Identificativo",
	MEASUREMENT_UNIT: "Unità di misura",
	COLOR: "Colore",
	TITLE: "Titolo",
	MESSAGE: "Messaggio",
	TIME: "Tempo",
	ACTION: "Azione", 
	ACTIONS: "Azioni", 
	NOTIFICATION: "Notifica",
	NOTIFICATIONS: "Notifiche",
	NOTIFICATION_TYPE: "Tipo notifica",
	EXTENSION : "Estensione",
    RESOLUTION : "Risoluzione",
    SIZE : "Dimensione",
	IMAGES: "Immagini",
	STATE: "Stato",
	EXPORT: "Esporta",
	SECONDS: "Secondi",
	NO_STATIONS_AVAILABLE: "Nessuna stazione disponibile",
	DATA: "Dati",
	MAINTENANCE: "Manutenzione",
	OTHERS: "Altro",
	DESTINATION: "Destinazione",
	POLAR_LEGEND_1: "Questo è un polarplot ricavato dall'integrazione di dati VOC e meteorologici delle ultime 24 ore. Dal",
    POLAR_LEGEND_2: "(probabilità più bassa) al",
    POLAR_LEGEND_3: "(probabilità più alta) si rappresenta una probabilità crescente di trovare la fonte dell'inquinamento.",
	FLAG: "Bandiera",
	SEARCH: "Cerca",
	LATITUDE: "Latitudine",
	LONGITUDE: "Longitudine",
	ACTION_GROUP: "Gruppo azioni",
	FILTERS_IN_VIEW: "Filtri in view",
	REQUIRED_INPUT: "Questo campo è obbligatorio",
	PARAM: "Parametro",
	PARAMS: "Parametri",
	ALREADY_EXIST: "già esistente",
	UPDATE_INTERVAL: "Intervallo di aggiornamento",
	UNIT_OF_MEASUREMENT: "Unità di misura",
	ALERT_TYPE: "Tipo alert",
	ALERT_CATEGORY: "Categoria alert",
	SHARED_WITH: "Condivisione con",
	REFRESH: "Aggiorna",
	SHOW_POSITION: "Mostra posizione",
	SHOW_PATH: "Mostra percorso",
	ENABLED_VIEWS: "Pagine abilitate",
	PERMITTED_ACTIONS: "Azioni consentite",
	GO_TO: "Vai a",
	GO_TO_STATIONS: "Vai alle stazioni",
	GO_TO_MULTIMEDIA: "Vai a multimedia",
	VIEWS: "Pagine",
	CAN_SHARE_WITH: "Può condividere con",
	TENANT_ACTIONS: "Azioni del tenant",
	USER_LIMIT: "Limite utenti",
	INVALID_DATE_ERROR: "Data non valida",
	MIN_DATE_ERROR: "Data di fine antecedente a quella di inizio",
	MAX_DATE_ERROR_GENERIC: "L'intervallo selezionato non può superare l'intervallo massimo consentito",
	MAX_DATE_ERROR: "L'intervallo selezionato non può superare",
	DISABLE_FUTURE_ERROR: "Data futura",
	WORKING: "Funzionante",
	PARTIALLY_WORKING: "Parzialmente funzionante",
	NOT_WORKING: "Non funzionante",
	NOT_AVAILABLE: "Non disponibile",
	CHECK_INVITATION : "Controlla inviti",
	AVAILABLE: "Disponibile",
	ACTIVE_STATIONS: "Stazioni attive",
	CUSTOMIZE_TIME_RANGE: "Personalizza l'intervallo di tempo",
	LAST_UPDATE: "Ultimo aggiornamento",
	CONNECT: "Connetti",
	DISCONNECT: "Disconnetti",
	NO_ACTIVE_STATIONS: "Nessuna stazione attiva",
	NO_ACTIVE_DEVICES: "Nessun device attivo",
	NEW_PLURAL: "Nuova/o",
	CORRELATION_MATRIX: "Matrice di correlazione",
	MIN_AND_MAX : "Minimo e massimo",
	MAX: "Massimo",
	MIN: "Minimo", 
	STATS_NOT_AVAILABLE: "Statistiche non disponibili",
	CLOSE: "Chiudi",
	SELECT_LAYER: "Seleziona il layer",
	ACTIVE_LAYER: "Layer attivo",
	MOTIVATION: "Motivazione",
	POSITION_ROUTE_NOT_AVAILABLE: "Posizione o percorso non disponibile",
	ALL_STATIONS: "Tutte le stazioni",
	ALL_DATA_SOURCES: "Tutte le sorgenti dati",
	MINUTE: "Minuto",
	MINUTES: "Minuti",
	HOUR: "Ora",
	HOURS: "Ore",
	THEME: "Tema",
	MARK_AS_READ: "Segna tutto come letto",
	DAY: "Giorno",
	DAYS: "Giorni",
	MONTH: "Mese",
	MONTHS: "Mesi",
	TYPOLOGY: "Tipologia",
	AIR_QUALITY: "Qualità dell'aria",
	WATER_QUALITY: "Qualità dell'acqua",
	EEA: "European Environment Agency",
	PARAMETERS_DETECTED: "Parametri rilevati",
	AUTHENTICATION_ERROR_TITLE: "Accesso negato",
	AUTHENTICATION_ERROR_MSG_0: "Il servizio di autenticazione non risponde. Controllare la connessione internet o contattare l'amministratore",
	AUTHENTICATION_ERROR_MSG_1: "Username o password errati si prega di riprovare",
	AUTHENTICATION_ERROR_MSG_2: "Servizio temporaneamente non disponibile",
	NEW_ALERT: "Nuovo alert",
	REPORT: "Segnalazione",
	REPORTS: "Segnalazioni",
	VESSEL: "Nave",
	VESSELS: "Navi",
	WIND_CALM: "Calma di vento",
	STATS: "Stats",
};

const vesselTypes = {
	VESSEL_TYPE_1: "Riservata per usi futuri",
	VESSEL_TYPE_2: "Riservata per usi futuri",
	VESSEL_TYPE_3: "Riservata per usi futuri",
	VESSEL_TYPE_4: "Riservata per usi futuri",
	VESSEL_TYPE_5: "Riservata per usi futuri",
	VESSEL_TYPE_6: "Riservata per usi futuri",
	VESSEL_TYPE_7: "Riservata per usi futuri",
	VESSEL_TYPE_8: "Riservata per usi futuri",
	VESSEL_TYPE_9: "Riservata per usi futuri",
	VESSEL_TYPE_10: "Riservata per usi futuri",
	VESSEL_TYPE_11: "Riservata per usi futuri",
	VESSEL_TYPE_12: "Riservata per usi futuri",
	VESSEL_TYPE_13: "Riservata per usi futuri",
	VESSEL_TYPE_14: "Riservata per usi futuri",
	VESSEL_TYPE_15: "Riservata per usi futuri",
	VESSEL_TYPE_16: "Riservata per usi futuri",
	VESSEL_TYPE_17: "Riservata per usi futuri",
	VESSEL_TYPE_18: "Riservata per usi futuri",
	VESSEL_TYPE_19: "Riservata per usi futuri",
	VESSEL_TYPE_20: 'Wing in ground (WIG), tutte le navi di questo tipo',
	VESSEL_TYPE_21: "Wing In Ground Categoria A pericolosa",
	VESSEL_TYPE_22: "Wing In Ground Categoria B pericolosa",
	VESSEL_TYPE_23: "Wing In Ground Categoria C pericolosa",
    VESSEL_TYPE_24: "Wing In Ground Categoria D pericolosa",
	VESSEL_TYPE_25: 'Wing in ground (WIG), Riservato per usi futuri',
	VESSEL_TYPE_26: 'Wing in ground (WIG), Riservato per usi futuri',
	VESSEL_TYPE_27: 'Wing in ground (WIG), Riservato per usi futuri',
	VESSEL_TYPE_28: 'Wing in ground (WIG), Riservato per usi futuri',
	VESSEL_TYPE_29: 'Wing in ground (WIG), Riservato per usi futuri',
	VESSEL_TYPE_30: 'Pesca',
	VESSEL_TYPE_31: 'Rimorchio',
	VESSEL_TYPE_32: 'Rimorchio: la lunghezza supera i 200 m o la larghezza supera i 25 m',
	VESSEL_TYPE_33: 'Dragaggio o operazioni subacquee',
	VESSEL_TYPE_34: 'Operazioni di immersione',
	VESSEL_TYPE_35: 'Operazioni militari',
	VESSEL_TYPE_36: "Vela",
	VESSEL_TYPE_37: 'Imbarcazione da diporto',
	VESSEL_TYPE_38: 'Riservato',
	VESSEL_TYPE_39: 'Riservato',
	VESSEL_TYPE_40: 'Imbarcazione ad alta velocità (HSC), tutte le navi di questo tipo',
	VESSEL_TYPE_41: 'Imbarcazione ad alta velocità (HSC), Categoria A pericolosa',
	VESSEL_TYPE_42: 'Imbarcazione ad alta velocità (HSC), Categoria B pericolosa',
	VESSEL_TYPE_43: 'Imbarcazione ad alta velocità (HSC), Categoria C pericolosa',
	VESSEL_TYPE_44: 'Imbarcazione ad alta velocità (HSC), Categoria D pericolosa',
	VESSEL_TYPE_45: 'Imbarcazione ad alta velocità (HSC), Riservato per uso futuro',
	VESSEL_TYPE_46: 'Imbarcazione ad alta velocità (HSC), Riservato per uso futuro',
	VESSEL_TYPE_47: 'Imbarcazione ad alta velocità (HSC), Riservato per uso futuro',
	VESSEL_TYPE_48: 'Imbarcazione ad alta velocità (HSC), Riservato per uso futuro',
	VESSEL_TYPE_49: 'Imbarcazione ad alta velocità (HSC), Nessuna informazione aggiuntiva',
	VESSEL_TYPE_50: "Nave Pilota",
	VESSEL_TYPE_51: "Navi di ricerca e salvataggio",
	VESSEL_TYPE_52: "Rimorchiatore",
	VESSEL_TYPE_53: 'Rimorchiatore portuale',
	VESSEL_TYPE_54: 'Attrezzatura antinquinamento',
	VESSEL_TYPE_55: "Forze dell'ordine",
	VESSEL_TYPE_56: 'Riserva - Nave locale',
	VESSEL_TYPE_57: 'Riserva - Nave locale',
	VESSEL_TYPE_58: 'Trasporto medico',
	VESSEL_TYPE_59: 'Nave non combattente secondo la risoluzione RR n. 18',
	VESSEL_TYPE_60: "Passeggeri",
	VESSEL_TYPE_61: 'Passeggeri, Categoria A pericolosa',
	VESSEL_TYPE_62: 'Passeggeri, Categoria B pericolosa',
	VESSEL_TYPE_63: 'Passeggeri, Categoria C pericolosa',
	VESSEL_TYPE_64: 'Passeggeri, Categoria D pericolosa',
	VESSEL_TYPE_65: 'Passeggeri, Riservato per uso futuro',
	VESSEL_TYPE_66: 'Passeggeri, Riservato per uso futuro',
	VESSEL_TYPE_67: 'Passeggeri, Riservato per uso futuro',
	VESSEL_TYPE_68: 'Passeggeri, Riservato per uso futuro',
	VESSEL_TYPE_69: 'Passeggeri, Nessuna informazione aggiuntiva',
	VESSEL_TYPE_70: "Cargo",
	VESSEL_TYPE_71: 'Cargo, Categoria A pericolosa',
  	VESSEL_TYPE_72: 'Cargo, Categoria B pericolosa',
  	VESSEL_TYPE_73: 'Cargo, Categoria C pericolosa',
  	VESSEL_TYPE_74: 'Cargo, Categoria D pericolosa',
  	VESSEL_TYPE_75: 'Cargo, Riservato per uso futuro',
  	VESSEL_TYPE_76: 'Cargo, Riservato per uso futuro',
  	VESSEL_TYPE_77: 'Cargo, Riservato per uso futuro',
  	VESSEL_TYPE_78: 'Cargo, Riservato per uso futuro',
  	VESSEL_TYPE_79: 'Cargo, Nessuna informazione aggiuntiva',
	VESSEL_TYPE_80: 'Nave cisterna, tutte le navi di questo tipo',
	VESSEL_TYPE_81: 'Nave cisterna, Categoria A pericolosa',
	VESSEL_TYPE_82: 'Nave cisterna, Categoria B pericolosa',
	VESSEL_TYPE_83: 'Nave cisterna, Categoria C pericolosa',
	VESSEL_TYPE_84: 'Nave cisterna, Categoria D pericolosa',
	VESSEL_TYPE_85: 'Nave cisterna, Riservato per uso futuro',
	VESSEL_TYPE_86: 'Nave cisterna, Riservato per uso futuro',
	VESSEL_TYPE_87: 'Nave cisterna, Riservato per uso futuro',
	VESSEL_TYPE_88: 'Nave cisterna, Riservato per uso futuro',
	VESSEL_TYPE_89: 'Nave cisterna, Nessuna informazione aggiuntiva',
	VESSEL_TYPE_90: "Altri tipi",
    VESSEL_TYPE_91: "Altri tipi (Hazardous category A)",
    VESSEL_TYPE_92: "Altri tipi (Hazardous category B)",
    VESSEL_TYPE_93: "Altri tipi (Hazardous category C)",
    VESSEL_TYPE_94: "Altri tipi (Hazardous category D)",
	VESSEL_TYPE_99: "Altri tipi (nessuna informazione)",
	VESSEL_TYPE_999: "Ecotaras",
};

const months = {
	MONTH_0: "Gennaio",
	MONTH_1: "Febbraio",
	MONTH_2: "Marzo",
	MONTH_3: "Aprile",
	MONTH_4: "Maggio",
	MONTH_5: "Giugno",
	MONTH_6: "Luglio",
	MONTH_7: "Agosto",
	MONTH_8: "Settembre",
	MONTH_9: "Ottobre",
	MONTH_10: "Novembre",
	MONTH_11: "Dicembre",
};

const formValidationErrorMessages = {
	ERR_FORM_EMAIL_INVALID : "Indirizzo email non valido",
	ERR_FORM_EMAIL_REQUIRED : "L'email è obbligatoria",
	ERR_FORM_USERNAME_REQUIRED : "Il nome utente è obbligatorio",
	ERR_FORM_PASSWORD_SHORT : "La password deve contenere almeno 8 caratteri",
	ERR_FORM_PASSWORD_UPPERCASE : "La password deve contenere almeno una lettera maiuscola.",
	ERR_FORM_PASSWORD_LOWERCASE : "La password deve contenere almeno una lettera minuscola.",
	ERR_FORM_PASSWORD_NUMBER : "La password deve contenere almeno un numero.",
	ERR_FORM_PASSWORD_REQUIRED : "La password è obbligatoria",
	ERR_FORM_LANGUAGE_REQUIRED : "La lingua è obbligatoria",
};

const locale = {
	...SIGN_IN_IT,
	...DASHBOARD_IT,
	...ADMINISTRATION_IT,
	...ALERTS_IT,
	...CONFIGURATION_IT,
	...ODOR_REPORTS_IT,
	...OPEN_DATA_AIR_QUALITY_IT,
	...OPEN_DATA_METEOROLOGY_IT,
	...SINGLE_MULTIMEDIA_IT,
	...SINGLE_STATION_IT,
	...STATIONS_IT,
	...USER_PROFILE_IT,
	...MULTIMEDIA_IT,
	...VESSELS_IT,
	...TEST_PAGE_IT,
	...FOOTER_IT,
	...generalTerms,
	...colors,
	...navbarGroupsNamesIt,
	...MONGO_CONSTANTS_IT,
	...SHARING_COMPONENTS_IT,
	...MODAL_COMPONENTS_IT,
	...INPUT_COMPONENTS_IT,
	...INTERNAL_NETWORK_MANAGEMENT_IT,
	// ...INTERACTIVE_MAP_IT,
	...TRASH_IT,
	...SELF_CONTAINED_VIEWS_IT,
	...MODALS_IT,
	...vesselTypes,
	...months,
	...formValidationErrorMessages,
};


export default locale;