
const STATION_TYPE = {
	STATION_TYPE_VESSEL : "Nave",
	STATION_TYPE_BUOY : "Boa",
	STATION_TYPE_NEST : "Nido",
	STATION_TYPE_CAR : "Vettura",
};

const SENSOR_TYPE = {
	SENSOR_TYPE_AIR : "Qualità dell'aria",
	SENSOR_TYPE_WATER : "Qualità dell'acqua",
	SENSOR_TYPE_SOIL : "Qualità del terreno",
	SENSOR_TYPE_GROUND : "Qualità del terreno",
	SENSOR_TYPE_BATTERY : "Batteria",
	SENSOR_TYPE_GPS : "Gps",
	SENSOR_TYPE_CAMERA : "Camera",
};

const TIMERANGE = {
  TIMERANGE_LIVE : "Live (ultima ora)",
  TIMERANGE_1H : "Ultima ora",
  TIMERANGE_2H : "Ultime 2 ore",
  TIMERANGE_3H : "Ultime 3 ore",
  TIMERANGE_6H : "Ultime 6 ore",
  TIMERANGE_12H : "Ultime 12 ore",
  TIMERANGE_1D: "Ultimo giorno",
  TIMERANGE_2D : "Ultimi 2 giorni",
  TIMERANGE_7D : "Ultimi 7 giorni",
  TIMERANGE_15D : "Ultimi 15 giorni",
  TIMERANGE_30D : "Ultimi 30 giorni",
  TIMERANGE_2M : "Ultimi 2 mesi",
  TIMERANGE_3M : "Ultimi 3 mesi",
  TIMERANGE_6M : "Ultimi 6 mesi",
  TIMERANGE_2MM : "2 minuti",
  TIMERANGE_5MM : "5 minuti",
  TIMERANGE_15MM : "15 minuti",
  TIMERANGE_10MM : "10 minuti",
  TIMERANGE_CUSTOM : "Personalizzato",
  "TIMERANGE_*" : "Personalizzato",
};

const ODOUR_LEVEL = {
  ODOUR_LEVEL_HIGH: "Elevato",
  ODOUR_LEVEL_MEDIUM: "Moderato",
  ODOUR_LEVEL_LOW: "Basso",
  ODOUR_LEVEL_ABSENT: "Nullo",
};

const ODOUR_TYPE = {
  ODOUR_TYPE_HYDROCARBONS: 'Idrocarburi',
  ODOUR_TYPE_ROASTING:'Torrefazione',
  ODOUR_TYPE_SEWER: 'Fogna',
  ODOUR_TYPE_OTHER:'Altro',
  ODOUR_TYPE_WASTE: 'Rifiuti',
  ODOUR_TYPE_ROTTEN_EGGS: 'Uova marce',
  ODOUR_TYPE_GAS: 'Gas',
  ODOUR_TYPE_BURNT: 'Bruciato',
  ODOUR_TYPE_AMMONIA: 'Ammoniaca',
  ODOUR_TYPE_ROTTEN_FISH: 'Pesce marcio',
  ODOUR_TYPE_PUNGENT_ACRID: 'Pungente acre',
  ODOUR_TYPE_MANURE: 'Letame',
  ODOUR_TYPE_SOLVENT: 'Solvente',
  ODOUR_TYPE_PUTRID: 'Putrido',
  ODOUR_TYPE_YEAST_LIKE: 'Simile a lievito',
};

const MEASURE = {
  MEASURE_CO2: "Anidride carbonica",
  MEASURE_NO2: "Biossido di azoto",
  MEASURE_O3: "Ozono",
  MEASURE_VOC: "Composti organici volatili (voc)",
  MEASURE_BVOC: "Voc respirabili (bvoc)",
  MEASURE_TEMP: "Temperatura",
  MEASURE_TEMP_SCD: "Temperatura scd",
  MEASURE_RASPBERRYTEMP: "Temperatura raspberry",
  MEASURE_WS: "Velocità del vento",
  MEASURE_WD: "Direzione del vento",
  MEASURE_PP: "Precipitazioni",
  MEASURE_SOLAR: "Energia solare",
  MEASURE_C6H6: "Benzene",
  MEASURE_TOLUENE: "Toluene",
  MEASURE_ETHYLBENZENE: "Etilbenzene",
  MEASURE_XILENE: "Xilene",
  MEASURE_PH: "pH",
  MEASURE_O2_DISC: "Ossigeno disciolto",
  MEASURE_COND_EL: "Conduttività elettrica",
  MEASURE_WATER_TEMP: "Temperatura acqua",
  MEASURE_PRES: "Pressione atmosferica",
  MEASURE_MAX_WAVE_HEIGHT: "Altezza massima dell'onda",
  MEASURE_SIGN_WAVE_HEIGHT: "Altezza significativa d'onda",
  MEASURE_AVG_WAVE_HEIGHT: "Altezza media d'onda",
  MEASURE_PEAK_PERIOD: "Periodo di picco d'onda",
  MEASURE_MEAN_WAVE_DIRECTION: "Direzione media del moto ondoso",
  MEASURE_MEAN_SPREAD: "Dispersione media d'onda",
  MEASURE_WAVE_SPECTRA32: "Spettro d'onda 32",
  MEASURE_WAVE_SPECTRA64: "Spettro d'onda 64",
  MEASURE_WAVE_SPECTRA128: "Spettro d'onda 128",
  MEASURE_GAS_RES: "Resistenza elettrica del gas",
  MEASURE_PM25: "Particolato 2.5",
  MEASURE_RH: "Umidità relativa",
  MEASURE_RH_SCD: "Umidità relativa scd",
  MEASURE_GAS_PERC: "Percentuale gassosa",
  MEASURE_IAQ: "Indice di qualità dell'aria bme",
  MEASURE_NO: "Monossido d'azoto",
  MEASURE_NOX: "Ossidi d'azoto (nox)",
  MEASURE_PM10: "Particolato 10",
  MEASURE_CO: "Monossido di carbonio",
  MEASURE_SO2: "Anidride solforosa",
  MEASURE_RAIN_INT: "Intensità piovosa",
  MEASURE_TOT_RAIN: "Pioggia totale",
  MEASURE_MSL: "Livello idrometrico",
  MEASURE_SALINITY: "Salinità",
  MEASURE_SOUND: "Onde sonore",
  MEASURE_BATTERY_STATUS: "Stato della batteria",
  MEASURE_BATTERY_CURRENT: "Corrente della batteria",
  MEASURE_CHARGING_STATUS: "Status di carica",
  MEASURE_DISCHARGING_STATUS: "Stato di scarico batteria",
  MEASURE_LAT: "Latitudine",
  MEASURE_LON: "Longitudine",
  MEASURE_PM1: "Particolato 1",
  MEASURE_ALT: "Altitudine",
  MEASURE_BATTERY_SOC: "Stato di carica della batteria",
  MEASURE_BATTERY_VOLTAGE: "Tensione della batteria",
  MEASURE_LOAD_CURRENT: "Corrente di carico",
  MEASURE_LOAD_VOLTAGE: "Tensione di carico",
  MEASURE_PV_VOLTAGE: "Tensione del pannello solare",
  MEASURE_PV_CURRENT: "Corrente del pannello solare",
  MEASURE_SPEC_SEA_WATER_GRAVITY: "Gravità specifica dell'acqua di mare",
  MEASURE_TOTAL_DISSOLVED_SOLID: "Solidi totali disciolti",
  MEASURE_TURBIDITY: "Torbidità",
  MEASURE_BASE_TURBIDITY: "Torbidità base",
  MEASURE_DISSOLVED_OXYGEN_PERC: "Ossigeno dissolto [% saturazione aria]",
  MEASURE_ELEC_CONDUCTIVITY_25: "Conduttività elettrica a 25 gradi celsius",
  MEASURE_ELEC_CONDUCTIVITY_20: "Conduttività elettrica a 20 gradi celsius",
  MEASURE_PROBE_DEPTH: "Profondità di immersione sonda",
  MEASURE_ELECTRICAL_RESISTIVITY: "Resistività elettrica",
  MEASURE_REDOX_POTENTIAL: "Potenziale redOx",
  MEASURE_SATELLITI: "Numero di satelliti",
  MEASURE_WINDROSE: "Rosa dei venti",
  MEASURE_VIDEO: "Video",
  MEASURE_IMAGE: "Foto / Immagini",
  MEASURE_AVG_PERIOD: "Periodo medio",
  MEASURE_WIND_CHILL: "Wind chill",
  MEASURE_UV: "Radiazioni UV",
  MEASURE_DEW_P: "Punto di rugiada",
  MEASURE_UNDERWATER_NOISE: "Rumore sottomarino",
  MEASURE_FREQ_END: "Frequenza finale",
  MEASURE_FREQ_BEGIN: "Frequenza iniziale",
  MEASURE_FREQ_MEAN: "Frequenza media",
  MEASURE_FREQ_MIN: "Frequenza minima",
  MEASURE_FREQ_MAX: "Frequenza massima",
  MEASURE_CLICK_NUMBER: "Numero di click",
  MEASURE_AQI: "Indice di qualità dell'aria",
  MEASURE_CO2_EQ: "Anidride carbonica (equivalente)",
  MEASURE_GPSACCURACY: "Precisione gps",
  MEASURE_SPEED: "Velocità",
  MEASURE_H2S: "Acido solfidrico",
  MEASURE_NH3: "Ammoniaca",
  MEASURE_CH4: "Metano",
  MEASURE_CHLOROPHYLL_A: "Clorofilla a",
  MEASURE_THOM_INDEX: "Indice di Thom",
  MEASURE_HEAT_INDEX: "Heat index",
  MEASURE_WIND_CHILL: "Wind chill",
  MEASURE_DISCOMFORT_INDEX: "Discomfort index",
  MEASURE_HUMIDEX: "Humidex",
}

const CAUSE_TYPE = {
  CAUSE_TYPRE_MAINTENANCE: "Manutenzione",
  CAUSE_TYPRE_OTHERS: "Altri",
  CAUSE_TYPRE_FUNCTIONALITY_EXPANSION: "Espansione funzionalità",
  CAUSE_TYPRE_EXPERIMENTATION_RESEARCH_START: "Inizio ricerca sperimentale",
  CAUSE_TYPRE_LIFECYCLE_END: "Fine ciclo di vita",
  CAUSE_TYPRE_MALFUNCTIONING: "Malfunzionamento",
  CAUSE_TYPRE_CHANGE_DESTINATION: "Cambio destinazione",
  CAUSE_TYPRE_DEPRECATED: "Deprecato",
  CAUSE_TYPRE_EXPERIMENTATION_RESEARCH_END: "Fine ricerca sperimentale",
};

export const MONGO_CONSTANTS_IT = {
    ...TIMERANGE,
    ...ODOUR_LEVEL,
    ...ODOUR_TYPE,
    ...MEASURE,
    ...STATION_TYPE,
    ...SENSOR_TYPE,
    ...CAUSE_TYPE,
};