import { useEffect, useMemo, useState } from "react";
import { Button, Paper, TextField, Typography, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Box } from "@mui/system";
import newLogoEvocs from "src/app/asset/images/logoEvocsWhite.png";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { activateUser } from "app/store/evocsSlice/user/userAction";
import LanguageSelect from "src/components/SharingComponents/Inputs/LanguageSelect";
import { routesNames } from "app/configs/routesNames";
import { viewNames } from "app/configs/routesNames";
import { useTranslation } from "react-i18next";
import { translate } from "src/utilities/utils";
import SuccessModal from "src/components/Modals/SuccessModal";
import LanguageSwitcher from "app/theme-layouts/shared-components/LanguageSwitcher";
import useRecaptchaV3 from "src/app/hooks/useRecaptchaV3";
import { checkRecaptchaV3 } from "app/store/evocsSlice/user/userAction";
import BlueEarth from "src/app/asset/images/earthWhiteBg.jpg";
import useMobile from "src/app/hooks/useMobile";
import * as yup from "yup";
import i18n from "src/i18n";
import emailVerified from "src/app/asset/images/emailVerified.png";

const getSiteKey = () => {
  const hostname = window.location.hostname;

  switch (hostname) {
    case "evocs.it":
      return process.env.REACT_APP_SITE_KEY_PROD;
    case "app.portinformer.cloud":
      return process.env.REACT_APP_SITE_KEY_TEST;
    case "localhost":
      return null;
    default:
      console.error("No site key configured for this hostname:", hostname);
      return null; // Fallback, se necessario
  }
};

const UserForm = ({ onRegister }) => {
  const { t, i18n } = useTranslation();
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.USER_INVITATION_VIEW);
  };

  const validationSchema = yup.object({
    userImage: yup.string(),
    email: yup
      .string()
      .email(handleTranslate("ERR_FORM_EMAIL_INVALID", true))
      .required(handleTranslate("ERR_FORM_EMAIL_REQUIRED", true)),
    username: yup
      .string()
      .required(handleTranslate("ERR_FORM_USERNAME_REQUIRED", true)),
    password: yup
      .string()
      .min(10, handleTranslate("ERR_FORM_PASSWORD_SHORT", true))
      .matches(/[A-Z]/, handleTranslate("ERR_FORM_PASSWORD_UPPERCASE", true))
      .matches(/[a-z]/, handleTranslate("ERR_FORM_PASSWORD_LOWERCASE", true))
      .matches(/\d/, handleTranslate("ERR_FORM_PASSWORD_NUMBER", true))
      .required(handleTranslate("ERR_FORM_PASSWORD_REQUIRED", true)),
    language: yup
      .string()
      .required(handleTranslate("ERR_FORM_LANGUAGE_REQUIRED", true)),
  });

  const [params] = useSearchParams();
  const invitationLang = params.get("lang"); // Ottieni la lingua dall'URL
  const isLocalhost = window.location.hostname === "localhost"; // Verifica se siamo in localhost
  const availableLang =  Object.keys(i18n.options.resources);

  const defaultInputs = useMemo(
    () => ({
      userImage: "",
      email: params.get("email") || "",
      username: params.get("email") || "",
      password: "",
      language:
        invitationLang && availableLang?.includes(invitationLang)
          ? invitationLang
          : i18n.language, // Inizializza con la lingua corrente di i18n
    }),
    [params, invitationLang, i18n.language]
  );

  const [inputs, setInputs] = useState(defaultInputs);
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [captchaValue, setCaptchaValue] = useState(true);

  const siteKey = getSiteKey();
  const executeRecaptcha = useRecaptchaV3(siteKey);

  useEffect(() => {
    validationSchema
      .validate(inputs, { abortEarly: false })
      .then(() => {
        setErrors({});
        setDisableSubmit(!isLocalhost ? !captchaValue : false); // Disabilita il submit solo se non in localhost e captchaValue è falso
      })
      .catch((err) => {
        const validationErrors = {};
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        setErrors(validationErrors);
        setDisableSubmit(true);
      });
  }, [inputs, captchaValue, isLocalhost]);

  useEffect(() => {
    // Aggiorna lo stato 'inputs.language' quando la lingua di i18n cambia
    setInputs((prevInputs) => ({
      ...prevInputs,
      language: i18n.language,
    }));
  }, [i18n.language]);

  const handleChangeInput = (key, value) => {
    setInputs((prev) => ({ ...prev, [key]: value }));
    setTouched((prev) => ({ ...prev, [key]: true }));
    if (key === "language") {
      i18n.changeLanguage(value);
      localStorage.setItem("evocs_lang", value);
    }
  };

  const handleConfirm = async (e) => {
    e.preventDefault();
    const secureCode = params.get("code");
    if (secureCode) {
      if (isLocalhost) {
        activateUser(
          inputs.email,
          inputs.username,
          inputs.password,
          secureCode,
          inputs.language,
          inputs.userImage
        ).then(() => {
          onRegister && onRegister();
        });
      } else {
        const recaptchaToken = await executeRecaptcha("login");
        const hostname = window.location.hostname;
        checkRecaptchaV3(recaptchaToken, hostname).then(() => {
          activateUser(
            inputs.email,
            inputs.username,
            inputs.password,
            secureCode,
            inputs.language,
            inputs.userImage
          ).then(() => {
            onRegister && onRegister();
          });
        });
      }
    }
  };

  return (
    <form
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "1rem",
        width: "100%",
      }}
      onSubmit={handleConfirm}
    >
      <div className="flex items-center justify-center">
        <Typography
          variant="h4"
          component="h2"
          className="font-bold text-center"
        >
          {handleTranslate("EMAIL_VERIFIED_SUCCESSFULLY")}
        </Typography>
      </div>

      <img
        className="rounded-sm  w-[400px] h-[380px]"
        src={emailVerified}
        alt="Verified email"
      ></img>
      <h2 className="mb-10"> {handleTranslate("CREATE_PROFILE_IN_SECONDS")} </h2>
      <TextField
        sx={{ width: "100%" }}
        id="email"
        label="Email"
        required
        disabled
        value={inputs.email}
        helperText={errors.email ?? ""}
        error={!!errors.email && touched.email}
      />
      <TextField
        sx={{ width: "100%" }}
        id="username"
        label="Username"
        required
        value={inputs.username}
        onChange={(e) => handleChangeInput("username", e.target.value)}
        helperText={errors.username ?? ""}
        error={!!errors.username && touched.username}
      />
      <TextField
        sx={{ width: "100%" }}
        id="password"
        label="Password"
        type="password"
        autoComplete="new-password"
        required
        value={inputs.password}
        onChange={(e) => handleChangeInput("password", e.target.value)}
        helperText={errors.password ?? ""}
        error={!!errors.password && touched.password}
      />
      <LanguageSelect
        fullWidth
        required
        value={inputs.language} // Usa il valore dallo stato 'inputs'
        onChange={(value) => handleChangeInput("language", value)}
        helperText={errors.language ?? ""}
        error={!!errors.language && touched.language}
      />
      <Button
        sx={{ width: "100%", padding: "10px 0px" }}
        disabled={disableSubmit}
        type="submit"
        variant="contained"
        color="primary"
      >
        {handleTranslate("REGISTER")}
      </Button>
    </form>
  );
};

const UserInvitation = () => {
  const navigate = useNavigate();
  const [successModal, setSuccessModal] = useState(false);
  const [params] = useSearchParams();
  const [xs, sm, md] = useMobile();
  const theme = useTheme();
  const handleRegister = () => {
    setSuccessModal(true);
  };

  const handleConfirm = () => {
    navigate(routesNames.SIGN_IN_VIEW);
  };

  
  const { t } = useTranslation();
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.USER_INVITATION_VIEW);
  };
  
  const availableLang =  Object.keys(i18n.options.resources);
  useEffect(() => {
    if (!availableLang) return;
    const invitationLang = params.get("lang");
    if (invitationLang && availableLang?.includes(invitationLang)) {
      i18n.changeLanguage(invitationLang);
      localStorage.setItem("evocs_lang", invitationLang);
    }
  }, [params]);

  const code = params.get("code");

  return code ? (
    <Grid
      container
      sx={{
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box sx={{ position: "absolute", top: "2rem", right: "2rem" }}>
        <LanguageSwitcher textColor="#242a30" />
      </Box>
      <Grid
        size={{ xs: 12, md: 3 }}
        sx={{
          background: "#242a30",
          color: "white",
          display: xs || sm || md ? "none" : "block",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            gap: "2rem",
            alignItems: "center",
            justifyContent: "center",
            padding: "1rem",
          }}
        >
          <img style={{ width: "250px" }} src={newLogoEvocs} alt="Logo" />
          <h1> {handleTranslate("CONFIRM_REGISTRATION")}</h1>
        </div>
      </Grid>
      <Grid
        size="grow"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "1rem",
          backgroundImage: `url(${BlueEarth})`,
          backgroundPosition: "left",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Paper
          style={{
            width: xs ? "100%" : "450px",
            padding: "2rem",
            backdropFilter: "blur(10px)",
            background: theme.palette.background.paper + "70",
          }}
        >
          <UserForm onRegister={handleRegister} />
        </Paper>
      </Grid>
      <SuccessModal
        isOpen={successModal}
        title={handleTranslate("REGISTRATION_SUCCESS")}
        confirmButton
        confirmText={handleTranslate("GO_TO_LOGIN", true)}
        onConfirm={handleConfirm}
      />
    </Grid>
  ) : (
    <Navigate to={routesNames.SIGN_IN_VIEW} />
  );
};

export default UserInvitation;