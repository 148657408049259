import { viewNames } from "app/configs/routesNames";
import { createLanguageObject } from "src/utilities/utils";

let USER_INVITATION_EN = {
  REGISTRATION_SUCCESS: "Registration completed successfully",
  CONFIRM_REGISTRATION: "Confirm your registration",
  REGISTER: "Sign up",
  ENTER_DETAILS: "Please enter your details correctly",
  CALL_TO_ACTION:
    "Protect your future, start today! With Evocs you can harness the power \
		of artificial intelligence for a more sustainable world. Evocs is the Tilebytes s.r.l. platform \
		that allows you to monitor the air quality in your city in real time, receive alerts \
		in case of pollution peaks, contribute to creating a data network for environmental protection \
		and much more. Register now on Evocs and: Discover the data on the air quality in your area. \
		Compare the data with other cities and countries. Customize your alerts and receive them directly \
		on your smartphone. Together, we can make a difference!.",
  SUBTITLE: "Welcome to our community",
  SIGN_UP_NOW: "Sign up now",
  ACCOUNT: "Already have an account?",
  SIGN_IN: "Sign in",
  DESCRIPTION:
    "Evocs is the Tilebytes s.r.l. platform that allows you to monitor the air quality in your city in real time, receive alerts in case of pollution peaks, contribute to creating a data network for environmental protection and much more.",
  EMAIL_VERIFIED_SUCCESSFULLY: "Email successfully verified!",
  CREATE_PROFILE_IN_SECONDS: "Now create your profile in just a few seconds",
};

USER_INVITATION_EN = createLanguageObject(
  USER_INVITATION_EN,
  viewNames.USER_INVITATION_VIEW
);

export const SELF_CONTAINED_VIEWS_EN = {
  ...USER_INVITATION_EN,
};
